// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Login } from "./Pages/Auth/Login"
// import { Main } from './Pages/Main';
// import { Admin } from './Pages/Admin';
// import ProtectedRoute from './Pages/Auth/ProtectedRoute';
// import { Contact } from './Pages/Contact';
// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Main />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route
//           path="/admin"
//           element={<ProtectedRoute element={<Admin />} />}
//         />

//       </Routes>
//     </Router>
//   );
// };

// export default App;






import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Login = lazy(() => import('./Pages/Auth/Login'));
const Main = lazy(() => import('./Pages/Main'));
const Admin = lazy(() => import('./Pages/Admin'));
const ProtectedRoute = lazy(() => import('./Pages/Auth/ProtectedRoute'));
const Contact = lazy(() => import('./Pages/Contact'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<ProtectedRoute element={<Admin />} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
